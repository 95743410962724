import React from "react"
import { Link, graphql } from 'gatsby';
import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = ({data}) => {
  console.log('data',data);
  return (
    <Layout>
      <SEO title="Home" />
      <h1>Hi people</h1>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.id}>
            <Link to={node.fields.slug}>
            <h3>
              {node.frontmatter.title}{" "}
            </h3>
            </Link>
            
          </div>
        ))}
      </div>
      
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          excerpt(truncate: true)
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }

`

export default IndexPage
